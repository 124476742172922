import React, { useEffect, useState } from "react";
import getBack from "../../assets/img/get-back.svg";
import { history } from "../../routes/router";
import { useParams } from "react-router-dom";
import { api, endpoints } from "../../api";
import SubSectionsSelector from "./Selector";

const ArticleComponent = () => {
  const [selectedArticle, setSelectedArticle] = useState();

  const { chapter, subsection, article } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const articleData = await api.get(endpoints.getArticleByTitle(article));

        setSelectedArticle(articleData.data.payload.data[0]);
      } catch (err) {}
    })();
  }, []);

  return (
    <div className="article">
      <SubSectionsSelector chapter={chapter} subsection={subsection} />
      <a
        onClick={() => {
          history.goBack();
        }}
        className="get-back-button"
      >
        <img src={getBack} />
      </a>
      <img src={selectedArticle?.photo} className="article-image" />
      <div className="link-block">
        <a href={selectedArticle?.link}>{selectedArticle?.link}</a>
      </div>
      <div>
        <div className="authors">{selectedArticle?.author}</div>
        <div className="article-title">{selectedArticle?.title}</div>
      </div>
      <div
        className="article-text"
        dangerouslySetInnerHTML={{ __html: selectedArticle?.article }}
      />
      <a
        onClick={() => {
          history.goBack();
        }}
        className="get-back-button"
      >
        <img src={getBack} />
      </a>
    </div>
  );
};

export default ArticleComponent;
