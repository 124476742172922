import React, { useEffect, useState } from "react";
import { api, endpoints } from "../../api";
import "./ParentsOfPreschoolers.scss";
import RoutePath from "./RoutePath";
import { Link, Switch } from "react-router-dom";
import { RouteWithSubRoutes } from "../../routes/router";

const ParentsOfPreschoolers = ({ routes }) => {
  const [chapters, setChapters] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const chaptersData = await api.get(endpoints.getChaptersData());

        setChapters(
          chaptersData.data.payload.data.sort((a, b) => {
            return a.sort - b.sort;
          })
        );
      } catch (err) {}
    })();
  }, []);

  console.log(chapters);

  return (
    <div className="wrapper">
      <div className="router-container">
        <RoutePath />
      </div>
      <div className="main-text">{chapters[0]?.title}</div>
      <div className="parentsofpreschoolers">
        <div className="chapters-container">
          {chapters.map((el, idx) => (
            <Link
              key={idx}
              to={`/parentsofpreschoolers/chapter/${el.slug}`}
              className={`chapter-block ${el.slug} ${
                window.location.href.includes(el.slug) ? "selected" : ""
              }`}
              disabled={window.location.href.includes(el.slug)}
            >
              {el.title}
            </Link>
          ))}
        </div>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
    </div>
  );
};

export default ParentsOfPreschoolers;
