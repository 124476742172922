import React, { useEffect, useState } from "react";
import { api, endpoints } from "../../api";
import { Link } from "react-router-dom";

const SubSectionsSelector = ({ chapter, subsection }) => {
  const [selectedSubSection, setSelectedSubSection] = useState();
  const [subSections, setSubSections] = useState();
  const [selectorIsOpen, setSelectorIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const selectedSubSectionData = await api.get(
          endpoints.getSubSectionsData(subsection)
        );
        const subSectionsData = await api.get(
          endpoints.getSubSectionsData(chapter)
        );

        setSubSections(subSectionsData.data.payload.data);
        setSelectedSubSection(selectedSubSectionData.data.payload.data[0]);
      } catch (err) {}
    })();
  }, [subsection]);

  return (
    <div
      className={selectorIsOpen ? "dropdown active" : "dropdown"}
      onClick={() => setSelectorIsOpen(!selectorIsOpen)}
    >
      <div className="dropdown-text">{selectedSubSection?.title}</div>
      <div className="dropdown-items">
        {subSections
          ?.filter((el) => {
            return el?.title !== selectedSubSection?.title;
          })
          .map((el, idx) => (
            <Link
              key={idx}
              to={`/parentsofpreschoolers/subsection/${chapter}/${el.slug}`}
              className="dropdown-item"
            >
              {el.title}
            </Link>
          ))}
      </div>
    </div>
  );
};

export default SubSectionsSelector;
