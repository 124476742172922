import axios from "axios";

export const api = axios.create({
  baseURL: "/api",
});

api.interceptors.request.use(function AxiosConfig(config) {
  const token = localStorage.getItem("userToken") || "";
  config.headers = {};
  config.headers["Content-Type"] = "application/json";
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export const endpoints = {
  getChaptersData: () => "/dictionary/chapters/data?limit=10&offset=0",
  getChapterBySlug: (slug) =>
    `/dictionary/chapters/data?limit=10&offset=0&search=${slug}`,
  getSubSectionsData: (slug) =>
    `/dictionary/subsections/data?limit=10&offset=0&search=${slug}`,
  getPostData: (slug) =>
    `/dictionary/post/data?limit=10&offset=0&search=${slug}`,
  getArticleByTitle: (title) =>
    `/dictionary/post/data?limit=10&offset=0&search=${title}`,
};
