import React, { useEffect, useState } from "react";
import { api, endpoints } from "../../api";
import "./ParentsOfPreschoolers.scss";
import SubSectionsDrawer from "./Selector";
import { Link, useParams } from "react-router-dom";

const PostDataComponent = ({ subSections }) => {
  const [postData, setPostData] = useState();
  const { chapter, subsection } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(endpoints.getPostData(subsection));

        setPostData(
          response.data.payload.data.sort((a, b) => {
            return a.sort - b.sort;
          })
        );
      } catch (err) {}
    })();
  }, [subsection]);

  return (
    <div className="sub-sections-container">
      <div className="articles-container">
        <SubSectionsDrawer chapter={chapter} subsection={subsection} />
        {postData?.map((el, idx) => {
          return (
            <Link
              to={`/parentsofpreschoolers/article/${chapter}/${subsection}/${el.title}`}
              key={idx}
              className="sub-section-block"
            >
              <img className="sub-section-image" src={el.photo} />
              <div className="sub-section-block-text">{el.title}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default PostDataComponent;
