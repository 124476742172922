import React, { useEffect, useState } from "react";
import { api, endpoints } from "../../api";
import "./ParentsOfPreschoolers.scss";
import { Link, useParams } from "react-router-dom";

const SubSectionsComponent = () => {
  const [subSections, setSubSections] = useState();
  const { chapter } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(endpoints.getSubSectionsData(chapter));

        setSubSections(
          response.data.payload.data.sort((a, b) => {
            return a.sort - b.sort;
          })
        );
      } catch (err) {}
    })();
  }, [chapter]);

  return (
    <div className="sub-sections-container">
      {subSections?.map(
        (el, idx) =>
          el?.chapterslug && (
            <Link
              key={idx}
              to={`/parentsofpreschoolers/subsection/${chapter}/${el.slug}`}
              className="sub-section-block"
            >
              <img src={el.photo} className="sub-section-image" />
              <div className="sub-section-block-text">{el.title}</div>
            </Link>
          )
      )}
    </div>
  );
};

export default SubSectionsComponent;
