import React from "react";
import "./App.scss";
import "./assets/fonts/Roboto/roboto.scss";
import PagesRouter from "./routes/router";

function App() {
  return <PagesRouter />;
}

export default App;
