import React, { useEffect, useState } from "react";
import { Link, matchPath } from "react-router-dom";
import { history } from "../../routes/router";
import { api, endpoints } from "../../api";

const RoutePath = () => {
  const [selectedChapter, setSelectedChapter] = useState();
  const [selectedSubSection, setSelectedSubSection] = useState();
  const [selectedPost, setSelectedPost] = useState();

  const articleMatch = matchPath(history.location.pathname, {
    path: "/parentsofpreschoolers/article/:chapter/:subsection/:article",
  });

  const subSectionMatch = matchPath(history.location.pathname, {
    path: "/parentsofpreschoolers/subsection/:chapter/:subsection",
  });

  useEffect(() => {
    (async () => {
      try {
        const selectedChapterData = await api.get(
          endpoints.getChapterBySlug(
            articleMatch
              ? articleMatch.params.chapter
              : subSectionMatch.params.chapter
          )
        );
        const selectedSubSectionData = await api.get(
          endpoints.getSubSectionsData(
            articleMatch
              ? articleMatch.params.subsection
              : subSectionMatch.params.subsection
          )
        );
        const selectedPostData = await api.get(
          endpoints.getArticleByTitle(
            articleMatch && articleMatch.params.article
          )
        );

        setSelectedChapter(selectedChapterData.data.payload.data[0]);
        setSelectedSubSection(selectedSubSectionData.data.payload.data[0]);
        setSelectedPost(selectedPostData.data.payload.data[0]);
      } catch (err) {}
    })();
  }, [history.location.pathname]);

  return (
    <>
      {articleMatch ? (
        <div className="router-container">
          <Link
            to={`/parentsofpreschoolers/chapter/${articleMatch?.params.chapter}`}
            className="route-link"
          >
            {selectedChapter?.title}
          </Link>
          &nbsp;/&nbsp;
          <Link
            to={`/parentsofpreschoolers/subsection/${articleMatch?.params.chapter}/${articleMatch?.params.subsection}`}
            className="route-link"
          >
            {selectedSubSection?.title}
          </Link>
          &nbsp;/&nbsp;
          <div className="chosen-route">{selectedPost?.title}</div>
        </div>
      ) : (
        subSectionMatch && (
          <div className="router-container">
            <Link
              to={`/parentsofpreschoolers/chapter/${subSectionMatch?.params.chapter}`}
              className="route-link"
            >
              {selectedChapter?.title}
            </Link>
            &nbsp;/&nbsp;
            <div className="chosen-route">{selectedSubSection?.title}</div>
          </div>
        )
      )}
    </>
  );
};

export default RoutePath;
