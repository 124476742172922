import filterActionTypes from "../types/filterTypes";

const initialState = {
  selected: {
    federalDistrict: { value: 0, label: "Выберите федеральный округ" },
    region: { value: 0, label: "Выберите субъект РФ" },
    municipality: { value: 0, label: "Выберите муниципалитет" },

    age: { year: 0, month: 2 },

    orientation: { value: 0, label: "Выберите направленность группы" },

    worktime: { value: 0, label: "Выберите режим работы группы" },

    availableCondition: [],

    availableWorktime: [],

    availableCabinet: [],

    availableSpecialist: [],

    wellness: [],

    ovz_type_new: [],

    consultationCenter: false,

    earlyAssistant: false,

    license: false,
    disableRange: true,
  },
  isSelected: false,
  input: "",

  foOptions: [{ value: 0, label: "Выберите федеральный округ" }],
  isFetchingFoOptions: false,
  errorMessageFoOptions: "",

  regionOptions: [{ value: 0, label: "Выберите субъект РФ" }],
  isFetchingRegionOptions: false,
  errorMessageRegionOptions: "",

  municipalityOptions: [{ value: 0, label: "Выберите муниципалитет" }],
  isFetchingMunicipalityOptions: false,
  errorMessageMunicipalityOptions: "",

  orientationOptions: [{ value: 0, label: "Выберите направленность группы" }],
  isFetchingOrientationOptions: false,
  errorMessageOrientationOptions: "",

  subOrientationOptions: [
    { value: 0, label: "Выберите направленность группы" },
  ],

  orientationOptionsValues: [
    { value: 1, label: "общеразвивающая" },
    { value: 2, label: "компенсирующая" },
    { value: 4, label: "оздоровительная" },
    { value: 6, label: "группы по присмотру и уходу" },
    { value: 7, label: "семейные дошкольные группы" },
  ],

  subOrientationOptionsObsheIKomb: [
    { value: 1, label: "глухие" },
    {
      value: 2,
      label: "слабослышащие и позднооглохшие",
    },
    { value: 3, label: "слепые" },
    { value: 4, label: "слабовидящие" },
    {
      value: 5,
      label: "с тяжелыми нарушениями речи ",
    },
    {
      value: 6,
      label: "с нарушениями опорно-двигательного аппарата (НОДА)",
    },
    {
      value: 7,
      label: "с задержкой психического развития ",
    },
    {
      value: 8,
      label: "с расстройством аутистического спектра ",
    },
    {
      value: 9,
      label: "с умственной отсталостью (нарушением интеллекта)",
    },
    {
      value: 10,
      label: "с тяжелыми и множественными нарушениями развития ",
    },
    {
      value: 11,
      label: "с синдромом дефицита внимания и гиперактивности (СДВГ)",
    },
    {
      value: 12,
      label: "дети после операции по кохлеарной имплантации",
    },
  ],

  subOrientationOptionsKomp: [
    { value: 1, label: "глухие" },
    {
      value: 2,
      label: "слабослышащие и позднооглохшие",
    },
    { value: 3, label: "слепые" },
    { value: 4, label: "слабовидящие" },
    {
      value: 5,
      label: "с тяжелыми нарушениями речи ",
    },
    {
      value: 6,
      label: "с нарушениями опорно-двигательного аппарата (НОДА)",
    },
    {
      value: 7,
      label: "с задержкой психического развития ",
    },
    {
      value: 8,
      label: "с расстройством аутистического спектра ",
    },
    {
      value: 9,
      label: "с умственной отсталостью (нарушением интеллекта)",
    },
    {
      value: 10,
      label: "с тяжелыми и множественными нарушениями развития ",
    },
    {
      value: 11,
      label: "с синдромом дефицита внимания и гиперактивности (СДВГ)",
    },
    {
      value: 12,
      label: "дети после операции по кохлеарной имплантации",
    },
  ],

  subOrientationOptionsOzd: [
    {
      value: 1,
      label: "группы для детей с туберкулезной интоксикацией",
    },
    {
      value: 2,
      label: "группы для часто болеющих детей",
    },
    { value: 3, label: "иной профиль" },
    {
      value: 4,
      label: "группы для детей с аллергопатологией",
    },
    {
      value: 5,
      label: "группы для детей с сахарным диабетом",
    },
    {
      value: 6,
      label: "группы для детей с заболеваниями органов дыхания",
    },
    {
      value: 7,
      label: "группы для детей с заболеваниями сердечно-сосудистой системы",
    },
    {
      value: 8,
      label: "группы для детей с нефро-урологическими заболеваниями",
    },
    {
      value: 9,
      label: "группы для детей с целиакией",
    },
  ],

  isFetchingSubOrientationOptions: false,
  errorMessageSubOrientationOptions: "",

  worktimeOptions: [],
  isFetchingWorktimeOptions: false,
  errorMessageWorktimeOptions: "",

  worktimeOptionsValues: [
    {
      value: 1,
      label: "кратковременного пребывания (до 5 часов в день)",
    },

    { value: 3, label: "полного дня (8-14 часов)" },

    {
      value: 5,
      label: "круглосуточного пребывания (24 часа)",
    },
  ],

  availableConditionOptions: [],
  isFetchingAvailableConditionOptions: false,
  errorMessageAvailableConditionOptions: "",

  availableConditionOptionsValues: [
    {
      label: "С нарушениями опорно-двигательного аппарата",
      value: "MUSCULOSKELETAL_DISORDERS",
    },
    {
      label: "С нарушениями зрения",
      value: "VISUAL_DISTURBANCES",
    },
    {
      label: "С нарушениями слуха",
      value: "HEARING_LOSS",
    },
  ],

  availableSpecialistOptions: [],
  isFetchingAvailableSpecialistOptions: false,
  errorMessageAvailableSpecialistOptions: "",

  availableSpecialistOptionsValues: [
    {
      label: "Педагог-психолог ",
      value: "EDUCATIONAL_PSYCHOLOGIST",
    },
    {
      label: "Учитель-логопед",
      value: "SPEECH_THERAPIST",
    },
    { label: "Учитель-дефектолог", value: "DEFECTOLOGIST" },
    {
      label: "Олигофренопедагог",
      value: "OLIGOPHRENOEDUCATOR",
    },
    {
      label: "Сурдопедагог",
      value: "SURDAPEDAGOGUE",
    },
    {
      label: "Тифлопедагог",
      value: "TTYPHLO_PEDAGOGUE",
    },
    {
      label: "Инструктор-методист ЛФК ",
      value: "PHYSICAL_THERAPY_INSTRUCTOR",
    },
    {
      label: "Инструктор по АФК ",
      value: "ADAPTIVE_PHYSICAL_EDUCATION_INSTRUCTOR",
    },
    {
      label: "Социальный педагог",
      value: "SOCIAL_PEDAGOGUE",
    },
    {
      label: "Врач-педиатр",
      value: "PEDIATRICIAN",
    },
    {
      label: "Врач-невролог",
      value: "NEUROLOGIST",
    },
    {
      label: "Врач-офтальмолог",
      value: "OPHTHALMOLOGIST",
    },
    {
      label: "Врач-сурдолог",
      value: "SURDOLOGIST",
    },

    { label: "Медицинский работник", value: "MED" },
  ],

  availableCabinetOptions: [
    { label: "кабинет психолога", value: "cabinet_psychologist" },
    { label: "кабинет дефектолога", value: "cabinet_defectologist" },
    { label: "кабинет логопеда ", value: "cabinet_logopedist" },
    { label: "медицинский кабинет", value: "cabinet_med" },
    { label: "спортивный зал", value: "sport_gym" },
    { label: "музыкальный (актовый) зал", value: "meeting_room" },
    { label: "бассейн", value: "pool" },
  ],

  extendedSearchOpen: false,

  filteredPoints: [],
  showFiltered: false,
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case filterActionTypes.FETCH_FO_OPTIONS_START:
      return { ...state, isFetchingFoOptions: true };
    case filterActionTypes.FETCH_FO_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingFoOptions: false,
        foOptions: [...initialState.foOptions, ...action.payload],
      };
    case filterActionTypes.FETCH_FO_OPTIONS_FAILURE:
      return {
        ...state,
        isFetchingFoOptions: false,
        errorMessageFoOptions: action.payload,
      };
    case filterActionTypes.SET_INPUT:
      return { ...state, input: action.payload };
    case filterActionTypes.SET_SELECT:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.name]: action.payload.value,
        },
      };
    case filterActionTypes.IS_SELECTED_ANY:
      return { ...state, isSelected: action.payload };
    case filterActionTypes.CLEAR_SELECTS:
      const changedSelected = state.selected;
      let isRegionChosen = true;
      action.payload.forEach((field) => {
        if (field === "region") {
          isRegionChosen = false;
        }
        changedSelected[field] = {
          value: 0,
          label:
            field === "federalDistrict"
              ? "Выберите федеральный округ"
              : field === "region"
              ? "Выберите субъект РФ"
              : isRegionChosen
              ? "Выберите муниципалитет"
              : "Выберите муниципалитет",
        };
      });
      return { ...state, selected: changedSelected };
    case filterActionTypes.CHANGE_SELECT_TO:
      return {
        ...state,
        selected: {
          ...state.selected,
          municipality: { value: 0, label: action.payload },
        },
      };

    case filterActionTypes.DISABLE_RANGE:
      return {
        ...state,
        selected: {
          ...state.selected,
          disableRange: action.payload,
        },
      };
    case filterActionTypes.FETCH_REGION_OPTIONS_START:
      return { ...state, isFetchingRegionOptions: true };
    case filterActionTypes.FETCH_REGION_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingRegionOptions: false,
        regionOptions: [...initialState.regionOptions, ...action.payload],
      };
    case filterActionTypes.FETCH_REGION_OPTIONS_FAILURE:
      return {
        ...state,
        isFetchingRegionOptions: false,
        errorMessageRegionOptions: action.payload,
      };

    case filterActionTypes.FETCH_MUNICIPALITY_OPTIONS_START:
      return { ...state, isFetchingMunicipalityOptions: true };
    case filterActionTypes.FETCH_MUNICIPALITY_OPTIONS_SUCCESS:
      if (action.payload && action.payload.length) {
        return {
          ...state,
          isFetchingMunicipalityOptions: false,
          selected: {
            ...state.selected,
            municipality: initialState.selected.municipality,
          },
          municipalityOptions: [
            ...initialState.municipalityOptions,
            ...action.payload,
          ],
        };
      }
      return state;
    case filterActionTypes.FETCH_MUNICIPALITY_OPTIONS_FAILURE:
      return {
        ...state,
        isFetchingMunicipalityOptions: false,
        errorMessageMunicipalityOptions: action.payload,
      };

    case filterActionTypes.FETCH_ORIENTATION_START:
      return {
        ...state,
        isFetchingOrientationOptions: false,
        orientationOptions: [
          ...initialState.orientationOptions,
          ...initialState.orientationOptionsValues,
        ],
      };

    // case filterActionTypes.FETCH_ORIENTATION_SUCCESS:
    //   if (action.payload && action.payload.length) {
    //     return {
    //       ...state,
    //       isFetchingOrientationOptions: false,
    //       orientationOptions: [
    //         ...initialState.orientationOptions,
    //         ...action.payload,
    //       ],
    //     };
    //   }
    //   return state;

    // case filterActionTypes.FETCH_ORIENTATION_FAILURE:
    //   return {
    //     ...state,
    //     isFetchingOrientationOptions: false,
    //     errorMessageOrientationOptions: action.payload,
    //   };

    case filterActionTypes.FETCH_SUB_ORIENTATION_START:
      return {
        ...state,
        isFetchingSubOrientationOptions: true,
      };

    case filterActionTypes.FETCH_SUB_ORIENTATION_SUCCESS:
      return {
        ...state,
        isFetchingSubOrientationOptions: false,
        subOrientationOptions: [
          ...initialState.subOrientationOptions,
          ...action.payload,
        ],
      };
    case filterActionTypes.FETCH_SUB_ORIENTATION_FAILURE:
      return {
        ...state,
        isFetchingSubOrientationOptions: false,
        errorMessageSubOrientationOptions: action.payload,
      };

    case filterActionTypes.CLEAR_SUB_ORIENTATION_SELECT:
      return {
        ...state,
        selected: {
          ...state.selected,
          subOrientation: initialState.selected.subOrientation,
        },
      };

    case filterActionTypes.FETCH_WORKTIME_START:
      return {
        ...state,
        isFetchingWorktimeOptions: true,
        worktimeOptions: [
          ...initialState.worktimeOptions,
          ...initialState.worktimeOptionsValues,
        ],
      };

    // case filterActionTypes.FETCH_WORKTIME_SUCCESS:
    //   return {
    //     ...state,
    //     isFetchingWorktimeOptions: false,
    //     worktimeOptions: [...initialState.worktimeOptions, ...action.payload],
    //   };

    // case filterActionTypes.FETCH_WORKTIME_FAILURE:
    //   return {
    //     ...state,
    //     isFetchingWorktimeOptions: false,
    //     errorMessageWorktimeOptions: action.payload,
    //   };

    case filterActionTypes.FETCH_AVAILABLE_CONDITION_START:
      return {
        ...state,
        isFetchingAvailableConditionOptions: true,

        availableConditionOptions: [
          ...initialState.availableConditionOptions,
          ...initialState.availableConditionOptionsValues,
        ],
      };

    // case filterActionTypes.FETCH_AVAILABLE_CONDITION_SUCCESS:
    //   return {
    //     ...state,
    //     isFetchingAvailableConditionOptions: false,
    //     availableConditionOptions: [
    //       ...initialState.availableConditionOptions,
    //       ...action.payload,
    //     ],
    //   };

    // case filterActionTypes.FETCH_AVAILABLE_CONDITION_FAILURE:
    //   return {
    //     ...state,
    //     isFetchingAvailableConditionOptions: false,
    //     errorMessageAvailableConditionOptions: action.payload,
    //   };

    case filterActionTypes.FETCH_AVAILABLE_SPECIALIST_START:
      return {
        ...state,
        isFetchingAvailableSpecialistOptions: true,
        availableSpecialistOptions: [
          ...initialState.availableSpecialistOptions,
          ...initialState.availableSpecialistOptionsValues,
        ],
      };

    // case filterActionTypes.FETCH_AVAILABLE_SPECIALIST_SUCCESS:
    //   return {
    //     ...state,
    //     isFetchingAvailableSpecialistOptions: false,
    //     availableSpecialistOptions: [
    //       ...initialState.availableSpecialistOptions,
    //       ...action.payload,
    //     ],
    //   };

    // case filterActionTypes.FETCH_AVAILABLE_SPECIALIST_FAILURE:
    //   return {
    //     ...state,
    //     isFetchingAvailableSpecialistOptions: false,
    //     errorMessageAvailableSpecialistOptions: action.payload,
    //   };

    case filterActionTypes.EXTENDED_SEARCH:
      return {
        ...state,
        extendedSearchOpen: true,
      };

    case filterActionTypes.CLOSE_EXTENDED_SEARCH:
      return {
        ...state,
        extendedSearchOpen: false,
      };

    // case filterActionTypes.SET_BIRTHDAY:
    //   return {
    //     ...state,
    //     selected: {
    //       ...state.selected,
    //       birthday: action.payload,
    //     },
    //   };

    case filterActionTypes.SET_AGE:
      return {
        ...state,
        selected: {
          ...state.selected,
          age: action.payload,
        },
      };

    case filterActionTypes.SET_CONSULTATION_CENTER:
      return {
        ...state,
        selected: {
          ...state.selected,
          consultationCenter: !state.selected.consultationCenter,
        },
      };
    case filterActionTypes.SET_EARLY_ASSISTANT_CENTER:
      return {
        ...state,
        selected: {
          ...state.selected,
          earlyAssistant: !state.selected.earlyAssistant,
        },
      };

    case filterActionTypes.SET_LICENSE:
      return {
        ...state,
        selected: {
          ...state.selected,
          license: !state.selected.license,
        },
      };

    case filterActionTypes.ADD_CLICKED_INPUT_VALUE:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.name]: [...action.payload.value],
        },
      };

    case filterActionTypes.REMOVE_CLICKED_INPUT_VALUE:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.name]: action.payload.value,
        },
      };

    case filterActionTypes.FETCH_FILTERED_POINTS_SUCCESS:
      return {
        ...state,
        filteredPoints: [...action.payload],
      };

    case filterActionTypes.SHOW_FILTERED_POINTS:
      return {
        ...state,
        showFiltered: action.payload,
      };

    case filterActionTypes.CLEAR_STATE:
      return {
        ...state,
        selected: {
          ...state.selected,
          age: { year: 0, month: 2 },

          orientation: { value: 0, label: "Выберите направленность группы" },
          subOrientation: { value: 0, label: "Выберите направленность группы" },
          worktime: { value: 0, label: "Выберите режим работы группы" },

          availableCondition: [],

          availableCabinet: [],

          availableWorktime: [],

          availableSpecialist: [],
          wellness: [],

          ovz_type_new: [],

          consultationCenter: false,

          earlyAssistant: false,
          license: false,
          disableRange: true,
        },
      };
    default:
      return state;
  }
};

export default filterReducer;
