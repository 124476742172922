import React from "react";

import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Layout from "../components/Layout";

import OrganizationsPage from "../components/OrganizationsPage/OrganizationsPage";
import CitiesAndRegionsPage from "../components/CitiesAndRegionsPage/CitiesAndRegionsPage";
import ParentsOfPreschoolers from "../components/ParentsOfPreschoolers/ParentsOfPreschoolersPage";
import PrivacyPolicyPage from "../components/PrivacyPolicyPage/PrivacyPolicyPage";
import SubSectionsComponent from "../components/ParentsOfPreschoolers/SubSections";
import PostDataComponent from "../components/ParentsOfPreschoolers/PostData";
import ArticleComponent from "../components/ParentsOfPreschoolers/Article";

export const history = createBrowserHistory();

const withLayout = (Component) => (props) =>
  (
    <Layout>
      <Component {...props} />
    </Layout>
  );

export const routes = [
  {
    path: "/",
    component: withLayout(OrganizationsPage),
    exact: true,
  },
  {
    path: "/citiesandregions",
    component: withLayout(CitiesAndRegionsPage),
  },
  {
    path: "/privacy-policy",
    component: withLayout(PrivacyPolicyPage),
    exact: true,
  },
  {
    path: "/parentsofpreschoolers",
    component: withLayout(ParentsOfPreschoolers),
    routes: [
      {
        path: `/parentsofpreschoolers/chapter/:chapter`,
        component: SubSectionsComponent,
      },
      {
        path: `/parentsofpreschoolers/subsection/:chapter/:subsection`,
        component: PostDataComponent,
      },
      {
        path: `/parentsofpreschoolers/article/:chapter/:subsection/:article`,
        component: ArticleComponent,
      },
    ],
  },
];

export function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

export default function PagesRouter() {
  return (
    <Router history={history}>
      <Switch>
        {routes.map((route, idx) => (
          <RouteWithSubRoutes key={idx} {...route} />
        ))}
      </Switch>
    </Router>
  );
}
